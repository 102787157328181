import { Component, OnInit } from '@angular/core';
import { TimelineItem } from '../../common/timeline/timeline-item';
import { ExperienceItem } from 'src/app/classes/experience/experience-item';
import { Technology } from 'src/app/enums/technology.enum';
import { Framework } from 'src/app/enums/framework.enum';
import { Software } from 'src/app/enums/software.enum';
import { Platform } from 'src/app/enums/platform.enum';

@Component({
  selector: 'experience-section',
  templateUrl: './experience-section.component.html',
  styleUrls: ['./experience-section.component.scss']
})
export class ExperienceSectionComponent implements OnInit {

  public positions: ExperienceItem[];

  constructor() {
    this.positions = [
      {
        date: "Jan 2022 - Present",
        title: "Full Stack Software Engineer",
        subtitle: "Osborne Clarke, Remote",
        description: "Working as part of a newly formed Software Engineering team to help implement industry best practice processes and standards whilst building bespoke software solutions.",
        responsibilities: [
          "Working closely with Osborne Clarke Solutions to expand and enhance their suite of collaboration tools",
          "Producing technical design documentation for existing/proposed new systems",
          "Creating feasibility reports for proposed functionality"
        ],
        achievements: [
          "Helped to define ISO27001 compliant secure development standards and processes",
          "Implemented advanced code scanning against existing code repositories",
          "Implemented an internal wiki system for documenting team knowledge"
        ],
        technologies: [
          Technology.CSharp, Technology.DotNet, Technology.Git, Technology.Html, Technology.JavaScript, Technology.TypeScript, Technology.Css,
          Technology.Sass, Technology.MicrosoftSQL, Framework.React, Framework.Bootstrap, Framework.DotNetWebApi, Software.DevOps, Software.VSCode, 
          Software.VisualStudio, Software.Github, Software.Office, Software.NuGet, Software.Npm
        ]
      },
      {
        date: "Sep 2019 - Jan 2022",
        title: "Senior Developer",
        subtitle: "BLM, Manchester",
        description: "Promoted to a role as the Senior Developer in an agile development team to primarily develop bespoke software solutions; namely a legal case management tool, FloCase.",
        responsibilities: [
          "Analyse systems, and requirements, to design and deliver new features.",
          "Drive system improvements to reduce technical debt.",
          "Advising management on key concepts of solutions, their architecture and limitations including potential improvements.",
          "Took full ownership of build and release processes through TFS, TeamCity and Octopus Deploy.",
          "Lead my team through the Agile process in daily stand ups, estimation and retrospective meetings.",
          "Documenting software solutions and processes for stakeholders",
          "Coaching and mentoring colleagues within my team."
        ],
        achievements: [
          "Supervised the development of bespoke client portal, BLM Realtime",
          "Created and implemented shared best practices for internal development."
        ],
        technologies: [
          Technology.CSharp, Technology.DotNet, Technology.Html, Technology.JavaScript, Technology.TypeScript,
          Technology.MicrosoftSQL, Technology.Git, Technology.Sass, Technology.Css, Technology.Powershell,
          Framework.AngularJs, Framework.Angular, Framework.Bootstrap, Framework.Kendo,
          Framework.Electron, Framework.DotNetWebApi, Software.TFS, Software.DevOps, Software.TeamCity,
          Software.Octopus, Software.ReSharper, Software.VisualStudio, Platform.IIS, Technology.Xml,
          Technology.Json
        ]
      },
      {
        date: "Oct 2018 - Aug 2019",
        title: "Developer",
        subtitle: "BLM, Manchester",
        description: "Working as a Full Stack .Net Developer in an agile development team to develop an internal legal case management tool, Flocase.",
        responsibilities: [
          "Delivering new features/bug fixes to systems with a high standard of code whilst following Agile and TDD practices",
          "Taking ownership of the release process and with it improve continuous development and integration processes using tools such as TeamCity, Octopus Deploy and Azure DevOps. This also involves preparing and leading all releases.",
          "Creation and implementation shared best practices for development teams.",
          "Lead my team through the Agile process in daily stand ups, estimation and retrospective meetings.",
          "Advising management on key concepts of the system, its architecture and limitations as well as potential improvements."
        ],
        achievements: [
          "Fully integrated all existing projects in to CI and CD processes",
          "Implemented shared coding standards"
        ],
        technologies: [
          Technology.CSharp, Technology.DotNet, Technology.Html, Technology.JavaScript, Technology.TypeScript,
          Technology.MicrosoftSQL, Technology.Git, Technology.Sass, Technology.Css, Technology.Powershell,
          Framework.AngularJs, Framework.Angular, Framework.Bootstrap, Framework.Kendo, Framework.Electron,
          Framework.DotNetWebApi, Software.TFS, Software.DevOps, Software.TeamCity, Software.Octopus,
          Software.ReSharper, Software.VisualStudio, Platform.IIS, Technology.Xml, Technology.Json
        ]
      },
      {
        date: "Jul 2017 - Sep 2018",
        title: "Software Engineer",
        subtitle: "Web Applications UK, Oldham",
        description: "Following a previously successful 14-month work placement between 2nd and 3rd year of university, I was offered the opportunity to return as a full-time employee. I worked within the development team to provide high quality support and tailored customisations for bespoke travel reservation systems.",
        responsibilities: [
          "Providing first line support and emergency out-of-hours support for customers and critical problems.",
          "Producing a high standard of code through the implementation of best practices during development work.",
          "Supervising cases from inception to completion through each stage of development whilst fulfilling a Lead Engineer role.",
          "Supplying mentorship and training to less experienced and new colleagues.",
          "Liaising with project managers and lead engineers to ensure my work is on schedule, satisfies the requirements of the specification and meets client expectations.",
          "Providing remote database administration services and deploying releases on live client environments.",
          "Interviewing potential intern, industrial placement and graduate candidates"
        ],
        achievements: [
          "Selected to represent the company's main Hack Manchester team and finishing runners up in the Best Company category.",
          "Successfully migrating a legacy monolith software product from traditional hosting to the Azure platform."
        ],
        technologies: [
          Technology.CSharp, Technology.DotNet, Technology.Html, Technology.JavaScript, Technology.JQuery,
          Technology.Css, Technology.MicrosoftSQL, Technology.SSRS, Technology.Node, Technology.Powershell,
          Technology.VBScript, Technology.ClassicAsp, Technology.Git, Framework.ExtJs, Framework.MVC,
          Platform.IIS, Platform.Azure, Platform.WinServer, Software.VisualStudio, Software.ReSharper,
          Software.SQLCompare, Technology.Xml, Technology.FoxPro
        ]
      },
      {
        date: "Jul 2015 - Aug 2016",
        title: "Software Engineer",
        subtitle: "Web Applications UK, Oldham",
        description: "A university industrial placement working with bespoke travel reservation systems; developing customisations to existing systems and fixing software bugs.",
        responsibilities: [
          "Providing first line support and emergency out-of-hours support for customers and critical problems.",
          "Producing a high standard of code through the implementation of best practices during development work.",
          "Liaising with project managers and lead engineers to ensure my work is on schedule, satisfies the requirements of the specification and meets client expectations.",
          "Interviewing potential intern and industrial placement candidates. As well as representing the company at grad/job fairs",
          "Helping to run regular code clubs for kids; and teachers, when computing was introduced to the curiculum."
        ],
        achievements: [
          "Selected to represent the company in a industrial placement Hack Manchester team.",
          "Offered summer extension and received a subsequent graduate job offer."
        ],
        technologies: [
          Technology.CSharp, Technology.DotNet, Technology.Html, Technology.JavaScript, Technology.JQuery,
          Technology.Css, Technology.MicrosoftSQL, Technology.SSRS, Technology.VBScript, Technology.ClassicAsp,
          Technology.Git, Framework.MVC, Platform.WinServer, Platform.IIS, Software.VisualStudio,
          Technology.Xml, Technology.FoxPro
        ]
      },
      {
        date: "Feb 2014 - Feb 2015",
        title: "Webmaster",
        subtitle: "William Morris Halls, Loughborough University",
        description: "A voluntary position, in which I was elected to fulfil by my peers, on my university hall of residence committee with the purpose of producing a wide variety of media, designing a website and managing social media outlets.",
        responsibilities: [
          "Working with the Adobe Creative Suite to design and produce media content.",
          "Regularly attending events to produce photographic and video content.",
          "Managing content to a strict set of guidelines on social media platforms to promote hall content and events.",
          "To design and maintain the hall website."
        ],
        achievements: [
          "Runner-up for Media Representative of the Year in 2014 and 2015.",
          "Runner-up for Media Hall of the Year in 2014 and 2015."
        ],
        technologies: [
          Technology.Wordpress, Technology.Html, Technology.JavaScript, Technology.Css, Technology.Php,
          Technology.MySql, Software.Photoshop, Software.PremierePro
        ]
      },
      {
        date: "May 2010 - Apr 2014",
        title: "Web Designer",
        subtitle: "Loversall Lakes, Doncaster",
        description: "Working for a local fishing business as the sole web designer to produce a customer facing website, loversall-lakes.co.uk.",
        responsibilities: [
          "Collecting requirements from stakeholders and submitting them to management.",
          "Continuously improving the website design with new techniques as my knowledge developed.",
          "Produced a modern and responsive website using Bootstrap.",
          "Produce high-quality photographic media",
          "Designing the company logo and a range of print media."
        ],
        achievements: [
          "Introducing the business to the use of social media to interact with customers"
        ],
        technologies: [
          Technology.ClassicAsp, Technology.Html, Technology.JavaScript, Technology.Css,
          Framework.Bootstrap, Framework.WinForms, Technology.Json, Software.Photoshop
        ]
      }
    ]
  }

  ngOnInit() {
  }

}
