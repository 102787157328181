<footer class="row m-0">
  <div id="copyright" class="col-xs-12 col-md align-text-bottom">
    <p class="mt-auto mb-auto p-2">&copy; {{currentYear}} Adam Chappell. All Rights Reserved.</p>
  </div>
  <div id="social" class="col-xs-12 col-md-4 align-text-bottom">
    <div class="mt-auto mb-auto p-2">
      <a rel="external" target="_blank" href="https://www.linkedin.com/in/akchappell/"><i class="fab fa-linkedin-in fa-2x p-1"></i></a>
      <a rel="external" target="_blank" href="https://twitter.com/achappell_94"><i class="fab fa-twitter fa-2x p-1"></i></a>
      <a rel="external" target="_blank" href="https://github.com/admchppll"><i class="fab fa-github fa-2x p-1"></i></a>
    </div>
  </div>
</footer>
