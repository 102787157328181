<mat-button-toggle-group id="showDetailedToggle" name="showDetailedToggle" aria-label="Show Detailed Experience List"
  [(ngModel)]="showDetailedString" *ngIf="showDetailedToggle">
  <mat-button-toggle value="basic">Show Basic</mat-button-toggle>
  <mat-button-toggle value="detailed">Show Detailed</mat-button-toggle>
</mat-button-toggle-group>
<div class="timeline-container">
  <div class="timeline-block timeline-block-right" *ngFor="let item of timelineItems">
    <div class="marker"></div>
    <div class="timeline-content">
      <h3>{{item.date}}</h3>
      <span class="timeline-title">{{item.title}}</span><br />
      <span *ngIf="item.subtitle != null" class="timeline-subtitle">{{item.subtitle}}</span>
      <div class="timeline-main-content">
        <p *ngIf="item.description != null">{{item.description}}</p>
        <app-headed-list
          *ngIf="item.responsibilities && item.responsibilities.length > 0 && showDetailedString === detailedString"
          listTitle="Responsibilities" [listItems]="item.responsibilities">
        </app-headed-list>
        <app-headed-list
          *ngIf="item.achievements && item.achievements.length > 0 && showDetailedString === detailedString"
          listTitle="Achievements" [listItems]="item.achievements">
        </app-headed-list>
        <app-inline-headed-list
          *ngIf="item.technologies && item.technologies.length > 0 && showDetailedString === detailedString"
          listTitle="Key Technologies" [listItems]="item.technologies">
        </app-inline-headed-list>
      </div>
    </div>
  </div>
</div>
