<section class="container">
  <div class="row">
    <div class="col">
      <h2>Skills</h2>
    </div>
  </div>
  <div class="row">
    <div class="col col-sm-6 col-md-4 mt-4" style="text-align: center">
        <fa-icon icon="infinity" size="4x"></fa-icon>
        <h3>CI & CD</h3>
        <ul>
          <li></li>
        </ul>
    </div>
    <div class="col col-sm-6 col-md-4 mt-4" style="text-align: center">
        <fa-icon icon="code" size="4x"></fa-icon>
        <h3>Code</h3>
        <ul>
          <li></li>
        </ul>
    </div>
    <div class="col col-sm-6 col-md-4 mt-4" *ngFor="let set of skillSet">
      <h4>{{set.section}}</h4>
      <app-unordered-list [listItems]="set.skills"></app-unordered-list>
    </div>
  </div>
</section>
