import { Component, OnInit } from '@angular/core';
import { Technology } from 'src/app/enums/technology.enum';
import { Software } from 'src/app/enums/software.enum';
import { Framework } from 'src/app/enums/framework.enum';
import { Platform } from 'src/app/enums/platform.enum';
import { Methodology } from 'src/app/enums/methodology.enum';

@Component({
  selector: 'skills-section',
  templateUrl: './skills-section.component.html',
  styleUrls: ['./skills-section.component.scss']
})
export class SkillsSectionComponent implements OnInit {
  public skillSet: SkillSet[];

  constructor() {
    this.skillSet = [
      {
        section: "Programming",
        skills: [
          Technology.CSharp,
          Technology.DotNet,
          Technology.JavaScript,
          Technology.MicrosoftSQL,
          Technology.Html,
          Technology.TypeScript,
          Technology.Powershell,
          Technology.Node,
          Technology.Php,
          Technology.Sass,
          Technology.JQuery
        ]
      },
      {
        section: "Tools",
        skills: [
          Software.VisualStudio,
          Software.VSCode,
          Software.TFS,
          Software.Photoshop
        ]
      },
      {
        section: "Frameworks",
        skills: [
          Framework.AngularJs,
          Framework.Angular,
          Framework.Bootstrap,
          Framework.ExtJs,
          Framework.Kendo,
          Framework.MVC,
          Framework.MVVM,
          Framework.WPF,
          Framework.WinForms,
          Framework.Materialize
        ]
      },
      {
        section: "Platforms",
        skills: [
          Platform.WinServer,
          Platform.Windows,
          Platform.CPanel,
          Platform.Azure,
          Platform.Linux
        ]
      },
      {
        section: "Methodologies",
        skills: [
          Methodology.Agile,
          Methodology.Scrum,
          Methodology.Waterfall,
          Methodology.CD,
          Methodology.CI,
          Methodology.TDD
        ]
      }
    ];
   }

  ngOnInit() {
    console.log(this.skillSet);
  }

}

export class SkillSet {
  section: string;
  skills: string[];
}
