<page-section sectionTitle="About Me">
  <div class="profile-image col-md-4 col-lg-3">
    <img src="../../../../assets/img/me.jpg">
  </div>
  <div class="col-md-8 col-lg-9 align-self-center">
    <div class="row">
      <p class="about-description">
        Currently working for Osborne Clarke developing bespoke internal systems, I'm a Full-Stack Software
        Engineer based in Manchester. I have been a computer phanatic for as long as I can remember and have been
        refining my programming knowledge and ability since 2010.
      </p>
      <p class="about-description">
        Loving everything tech, I love to spend my spare time tinkering on my side projects/learning something new
        and away from programming, I have a passion in all things digital media and design through photography,
        video editing and graphic design.
      </p>
    </div>
  </div>
</page-section>
