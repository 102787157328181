import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mouse-scroll',
  templateUrl: './mouse-scroll.component.html',
  styleUrls: ['./mouse-scroll.component.scss']
})
export class MouseScrollComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
