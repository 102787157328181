import { Component, OnInit, Input } from '@angular/core';
import { TimelineItem } from './timeline-item';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  inputs: ['timelineItems','showDetailedToggle']
})
export class TimelineComponent implements OnInit {
  showDetailedString: string;
  detailedString: string = "detailed";
  basicString: string = "basic";

  @Input() timelineItems: TimelineItem[];
  @Input() showDetailedToggle: boolean = false;

  constructor() {
    this.showDetailedString = this.basicString;
  }

  ngOnInit() {
  }

  

}
