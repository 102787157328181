import { Component, OnInit } from '@angular/core';
import { PersonalFact } from '../../common/personal-fact/personal-fact';

@Component({
  selector: 'about-section',
  templateUrl: './about-section.component.html',
  styleUrls: ['./about-section.component.scss']
})
export class AboutSectionComponent implements OnInit {
  public Facts: PersonalFact[];

  constructor() {
    this.Facts = [
      { Label: 'Name', Detail: 'Adam Chappell' },
      { Label: 'Age', Detail: '24' },
      { Label: 'Job', Detail: 'Developer' },
      { Label: 'Citizenship', Detail: 'British' },
      { Label: 'Location', Detail: 'Manchester' },
      { Label: 'Email', Detail: 'adam@adamchappell.com' }      
    ];
  }

  ngOnInit() {
  }

}
