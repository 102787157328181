import { Component, OnInit, Input } from '@angular/core';
import Typed from 'typed.js';

@Component({
  selector: 'typed-glitch-hero',
  templateUrl: './typed-glitch-hero.component.html',
  styleUrls: ['./typed-glitch-hero.component.scss'],
  inputs: ['title', 'subtitles']
})
export class TypedGlitchHeroComponent implements OnInit {
  @Input() mainTitle: string;
  @Input() subtitles: string[];
  
  constructor() { }

  ngOnInit() {
    var typed = new Typed('#typed', {
      stringsElement: '#typed-strings',
      loop: true,
      typeSpeed: 40,
      backDelay: 3000
    });
  }

}
