import { Component, OnInit } from '@angular/core';
import { PastProject } from './past-project';

@Component({
  selector: 'past-projects',
  templateUrl: './past-projects.component.html',
  styleUrls: ['./past-projects.component.scss']
})
export class PastProjectsComponent implements OnInit {

  public pastProjects: PastProject[];

  constructor() {
    this.pastProjects = [
      { name: "Kare Adenegan", logoPath: "assets/img/kare.png", url: "https://bit.ly/kareadenegan" },
      { name: "Loversall Lakes", logoPath: "assets/img/loversall-lakes.png", url: "https://bit.ly/loversall-lakes-uk" },
      { name: "MG1", logoPath: "assets/img/MG1.png", url: "https://bit.ly/mangementgroup1" },
      { name: "Incus Performance", logoPath: "assets/img/incus.png", url: "" }
    ];
  }

  ngOnInit() {
  }

}
