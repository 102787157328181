<div class="blog-preview-section">
  <section class="align-items-center container">
    <div class="row">
      <div class="col">
        <h2>Blog Posts</h2>
      </div>
    </div>
    <div class="row">

    </div>
  </section>
</div>