<section class="container mt-5 mb-3">
  <div class="row">
    <div class="col">
      <h2 class="mb-4">{{sectionTitle}}</h2>
    </div>
  </div>
  <div class="row">
    <ng-content></ng-content>
  </div>
</section>
