import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'glitch-hero',
  templateUrl: './glitch-hero.component.html',
  styleUrls: ['./glitch-hero.component.scss']
})
export class GlitchHeroComponent implements OnInit {
  @Input() mainTitle: string;
  @Input() subtitle: string;
  
  constructor() { }

  ngOnInit() {
  }

}
