<home-hero-section *ngIf="!ShowComingSoon"></home-hero-section>
<about-section *ngIf="!ShowComingSoon"></about-section>
<experience-section *ngIf="!ShowComingSoon"></experience-section>
<past-projects *ngIf="!ShowComingSoon"></past-projects>
<education-section *ngIf="!ShowComingSoon"></education-section>
<call-to-action *ngIf="!ShowComingSoon"></call-to-action>
<coming-soon *ngIf="ShowComingSoon"></coming-soon>
<!--
<past-projects *ngIf="!ShowComingSoon" class="mt-4"></past-projects>
<blog-post-preview></blog-post-preview>
<projects-preview></projects-preview>
-->
