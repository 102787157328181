export enum Platform {
    Windows = "Windows",
    WinServer = "Windows Server",
    Azure = "Microsoft Azure",
    CPanel = "CPanel",
    Linux = "Linux",
    Cisco = "Cisco",
    OSX = "MacOS",
    IIS = "Internet Information Services (IIS)"
}
