import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-headed-list',
  templateUrl: './headed-list.component.html',
  styleUrls: ['./headed-list.component.scss'],
  inputs: ['listTitle','listItems']
})
export class HeadedListComponent implements OnInit {

  @Input() listTitle: string;
  @Input() listItems: string[];
  
  constructor() { }

  ngOnInit() {
  }

}
