import { Component, OnInit } from '@angular/core';
import Typed from 'typed.js'

@Component({
  selector: 'home-hero-section',
  templateUrl: './hero-section.component.html',
  styleUrls: ['./hero-section.component.scss']
})
export class HeroSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    var typed = new Typed('#typed', {
      stringsElement: '#typed-strings',
      loop: true,
      typeSpeed: 40,
      backDelay: 3000
    });
  }

}
