import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'social-media-icons',
  templateUrl: './social-media-icons.component.html',
  styleUrls: ['./social-media-icons.component.scss']
})
export class SocialMediaIconsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
