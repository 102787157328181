import { Component, OnInit, Input } from '@angular/core';
import { PersonalFact } from './personal-fact';

@Component({
  selector: 'personal-fact',
  templateUrl: './personal-fact.component.html',
  styleUrls: ['./personal-fact.component.scss'],
  inputs: ['fact']
})
export class PersonalFactComponent implements OnInit {
  @Input() fact: PersonalFact;

  constructor() { }

  ngOnInit() {
  }

}
