import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PageInformation } from 'src/app/classes/pages/page-information';
import { PageInformationService } from 'src/app/services/page-information/page-information.service';

@Component({
  selector: 'resume-page',
  templateUrl: './resume.component.html',
  styleUrls: ['./resume.component.scss']
})
export class ResumeComponent implements OnInit {
  public pageInformation: PageInformation;

  constructor(private pageInformationService: PageInformationService) {
    this.pageInformation = new PageInformation();
    this.pageInformation.title = "My Resume";
  }

  ngOnInit() {
    this.pageInformationService.setPageInformation(this.pageInformation);
  }

}
