export class PageInformation {
    title: string;
    description: string;
    author: string;
    keywords: string[];

    constructor(){
        this.keywords = [];
    }
}
