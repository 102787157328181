import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoadingScreenComponent } from './components/common/loading-screen/loading-screen.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { LoadingScreenInterceptor } from './interceptors/loading-screen-interceptor';
import { HeroSectionComponent } from './components/home/hero-section/hero-section.component';
import { AboutSectionComponent } from './components/home/about-section/about-section.component';
import { ExperienceSectionComponent } from './components/resume/experience-section/experience-section.component';
import { EducationSectionComponent } from './components/resume/education-section/education-section.component';
import { SkillsSectionComponent } from './components/resume/skills-section/skills-section.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { HeaderComponent } from './components/common/header/header.component';
import { NavigationComponent } from './components/common/navigation/navigation.component';
import { SocialMediaIconsComponent } from './components/common/social-media-icons/social-media-icons.component';
import { HomeComponent } from './components/home/home/home.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { PersonalFactComponent } from './components/common/personal-fact/personal-fact.component';
import { TimelineComponent } from './components/common/timeline/timeline.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { MouseScrollComponent } from './components/common/mouse-scroll/mouse-scroll.component';
import { ResumeComponent } from './components/resume/resume/resume.component';
import { ProjectsPreviewComponent } from './components/home/projects-preview/projects-preview.component';
import { BlogPostPreviewComponent } from './components/home/blog-post-preview/blog-post-preview.component';
import { ProjectsComponent } from './components/projects/projects/projects.component';
import { BlogComponent } from './components/blog/blog/blog.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GlitchHeroComponent } from './components/common/hero/glitch-hero/glitch-hero.component';
import { TypedGlitchHeroComponent } from './components/common/hero/typed-glitch-hero/typed-glitch-hero.component';
import { HeadedListComponent } from './components/common/lists/headed-list/headed-list.component';
import { UnorderedListComponent } from './components/common/lists/unordered-list/unordered-list.component';
import { FormsModule } from '@angular/forms';
import { TestimonialsSectionComponent } from './components/resume/testimonials-section/testimonials-section.component';
import { PageSectionComponent } from './components/common/page/page-section/page-section.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { PastProjectsComponent } from './components/resume/past-projects/past-projects.component';
import { InlineHeadedListComponent } from './components/common/lists/inline-headed-list/inline-headed-list.component';
import { InlineListComponent } from './components/common/lists/inline-list/inline-list.component';
import { CallToActionComponent } from './components/home/call-to-action/call-to-action.component';
//import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fas);


@NgModule({
  declarations: [
    AppComponent,
    LoadingScreenComponent,
    HeroSectionComponent,
    AboutSectionComponent,
    ExperienceSectionComponent,
    EducationSectionComponent,
    SkillsSectionComponent,
    FooterComponent,
    HeaderComponent,
    NavigationComponent,
    SocialMediaIconsComponent,
    HomeComponent,
    NotFoundComponent,
    PersonalFactComponent,
    ComingSoonComponent,
    TimelineComponent,
    MouseScrollComponent,
    ResumeComponent,
    ProjectsPreviewComponent,
    BlogPostPreviewComponent,
    ProjectsComponent,
    BlogComponent,
    GlitchHeroComponent,
    TypedGlitchHeroComponent,
    HeadedListComponent,
    UnorderedListComponent,
    TestimonialsSectionComponent,
    PageSectionComponent,
    PastProjectsComponent,
    InlineHeadedListComponent,
    InlineListComponent,
    CallToActionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
    MatButtonModule,
    MatButtonToggleModule,
    FormsModule,
    FontAwesomeModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
