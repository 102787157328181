<page-section sectionTitle="Contact Me">
  <p class="col-12 align-center p-1 pb-2 align-center">
    If you would like to get a hold of me, please feel free to contact me at <a href="mailto:adam@adamchappell.com"
      class="contact-link">adam@adamchappell.com</a> or at any of my social media below.
  </p>
  <div class="col-md-4 contact-link p1 pb-2">
    <a rel="external" target="_blank" href="https://www.linkedin.com/in/akchappell/"><i
        class="fab fa-linkedin-in p-1"></i> <span class="p-1">akchappell</span> <i class="fas fa-link"></i></a>
  </div>
  <div class="col-md-4 contact-link p1 pb-2">
    <a rel="external" target="_blank" href="https://twitter.com/achappell_94"><i class="fab fa-twitter p-1"></i>
      <span class="p-1">achappell_94</span> <i class="fas fa-link"></i></a>
  </div>
  <div class="col-md-4 contact-link p1 pb-2">
    <a rel="external" target="_blank" href="https://github.com/admchppll"><i class="fab fa-github p-1"></i> <span
        class="p-1">admchppll</span> <i class="fas fa-link"></i></a>
  </div>
</page-section>
