export enum Framework {
    Bootstrap = "Bootstrap",
    React = "React",
    Vue = "VueJS",
    Ionic = "Ionic",
    WinForms = "Windows Forms",
    Electron = "Electron",
    AngularJs = "AngularJs",
    Angular = "Angular",
    MVVM = "MVVM",
    MVC = "MVC",
    WPF = "WPF",
    ExtJs = "Ext JS",
    Kendo = "Kendo UI",
    Materialize = "Materialize",
    WinServices = "Windows Services",
    DotNetWebApi = "ASP.NET Web API"
}
