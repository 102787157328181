import { Component, OnInit } from '@angular/core';
import { TimelineItem } from '../../common/timeline/timeline-item';

@Component({
  selector: 'education-section',
  templateUrl: './education-section.component.html',
  styleUrls: ['./education-section.component.scss']
})
export class EducationSectionComponent implements OnInit {

  public schools: TimelineItem[];

  constructor() {
    this.schools = [
      {
        date: "2013 - 2017",
        title: "Loughborough University",
        subtitle: "Computer Science",
        description: "I obtained a First Class Honors degree in Computer Science (BSc) with a Diploma in Professional Studies (DPS) with a year in industry."
      },
      {
        date: "2006 - 2013",
        title: "Balby Carr Community Sports and Science College",
        subtitle: "Secondary School and Sixth Form",
        description: "I attained 3 A Levels in Maths, Accounting and Physics at grades AAB respectively as well as 5+ GCSE's A* - C (including Maths and English)."
      }
    ]
  }

  ngOnInit() {
  }

}
