<div class="coming-soon-section container">
  <section>
      <h1 data-text="I'M ADAM CHAPPELL">I'M ADAM CHAPPELL</h1>
      <div id="typed-strings">
          <p>Full Stack Software Engineer.</p>
          <p>Based in Manchester,UK.</p>
      </div>
      <span id="typed"></span>
      <div>
        <p>Thank you for visiting my website, I'm still in the process of building everything but feel free to contact me on social media or by email at <a href="mailto:adam@adamchappell.com">adam@adamchappell.com</a></p>
        <social-media-icons></social-media-icons>
      </div>
  </section>
</div>