import { Component } from '@angular/core';
import { environment } from '../environments/environment'
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Adam Chappell';

  public ShowHeader: boolean;
  public ShowFooter: boolean;

  constructor(private router: Router){
    this.ShowHeader = false;//!environment.comingSoon
    this.ShowFooter = !environment.comingSoon

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        (<any>window).ga('set', 'page', event.urlAfterRedirects);
        (<any>window).ga('send', 'pageview');
      }
    });
  }
}
