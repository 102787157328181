import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-inline-headed-list',
  templateUrl: './inline-headed-list.component.html',
  styleUrls: ['./inline-headed-list.component.scss'],
  inputs: ['listTitle', 'listItems']
})
export class InlineHeadedListComponent implements OnInit {

  @Input() listTitle: string;
  @Input() listItems: string[];

  constructor() { }

  ngOnInit() {
  }

}
