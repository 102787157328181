import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'testimonials-section',
  templateUrl: './testimonials-section.component.html',
  styleUrls: ['./testimonials-section.component.scss']
})
export class TestimonialsSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
