import { Injectable } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { PageInformation } from '../../classes/pages/page-information';
import { AppComponent } from '../../app.component';
import { environment } from '../../../environments/environment';
import { OpenGraphMetaType } from './open-graph-meta-type.enum';
import { TwitterMetaType } from './twitter-meta-type.enum';
import { MetaType } from './meta-type.enum';

@Injectable({
  providedIn: 'root'
})
export class PageInformationService {

  constructor(private titleService: Title, private metaService: Meta) { }

  public setPageInformation(information: PageInformation){
    let pageTitle = `${information.title} | ${environment.siteName}`
    this.titleService.setTitle(pageTitle);

    this.metaService.updateTag({ name: MetaType.Description, content: information.description});
    this.metaService.updateTag({ name: MetaType.Author, content: information.author ? information.author : environment.siteAuthor});
    this.metaService.updateTag({ name: MetaType.Keywords, content: information.keywords.join(",")});
  }
}
