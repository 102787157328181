<header>
  <img class="header-logo" src="../../../../assets/img/header-logo.png">
  <nav>
    <button mat-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
      <span>Menu</span>
    </button>
    <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
      <button mat-menu-item routerLink="/">
        <mat-icon>home</mat-icon>
        <span>Home</span>
      </button>
      <button mat-menu-item routerLink="/resume">
        <mat-icon>work</mat-icon>
        <span>Resume</span>
      </button>
      <button mat-menu-item routerLink="/projects">
        <mat-icon>assignment</mat-icon>
        <span>Projects</span>
      </button>
      <button mat-menu-item routerLink="/blog">
        <mat-icon>forum</mat-icon>
        <span>Blog</span>
      </button>
      <button mat-menu-item routerLink="/contact">
        <mat-icon>email</mat-icon>
        <span>Contact</span>
      </button>
    </mat-menu>
  </nav>
</header>
