export enum Technology {
    CSharp = "C#",
    DotNet = "ASP.NET",
    Java = "Java",
    JavaScript = "JavaScript",
    TypeScript = "TypeScript",
    Html = "HTML",
    Sass = "SASS",
    Css = "CSS",
    VBScript = "VBScript",
    Node = "NodeJS",
    MySql = "MySQL",
    MicrosoftSQL = "Microsoft SQL Server",
    SQLite = "SQLite",
    Php = "PHP",
    ClassicAsp = "Classic ASP",
    JQuery = "JQuery",
    Wordpress = "WordPress",
    Haskell = "Haskell",
    ProLog = "ProLog",
    Git = "Git",
    Powershell = "PowerShell",
    SSRS = "SQL Server Reporting Services (SSRS)",
    Xml = "XML",
    Json = "JSON",
    SSL = "SSL/TLS",
    FoxPro = "Microsoft FoxPro"
}
