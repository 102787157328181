<section class="hero-section">
  <section>
      <h1 class="glitch" data-text="I'M ADAM CHAPPELL">I'M ADAM CHAPPELL</h1>
      <div id="typed-strings">
          <p>Full Stack Software Engineer.</p>
          <p>Based in Manchester, UK.</p>
      </div>
      <span id="typed"></span>   
  </section>
    <app-mouse-scroll class="bottom-center"></app-mouse-scroll>  
</section>