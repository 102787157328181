import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PageInformation } from '../../../classes/pages/page-information';
import { PageInformationService } from '../../../services/page-information/page-information.service';

@Component({
  selector: 'blog-page',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  public pageInformation: PageInformation;

  constructor(private pageInformationService: PageInformationService) {
    this.pageInformation = new PageInformation();
    this.pageInformation.title = "My Blog";
    this.pageInformation.description = "This is my blog. Blah blah blah!";
    this.pageInformation.keywords = ["Computer","Stuff", "Thing-a-majig"];
  }

  ngOnInit() {
    this.pageInformationService.setPageInformation(this.pageInformation);
  }

}
