import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'home-page',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  title = 'Adam Chappell';
  
  public ShowComingSoon: boolean;

  constructor(){
    this.ShowComingSoon = environment.comingSoon;
  }

  ngOnInit() {
  }

}
