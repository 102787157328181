export enum Software {
    VisualStudio = "Visual Studio",
    VSCode = "Visual Studio Code",
    Npm = "NPM",
    SSMS = "SQL Server Management Studio",
    ReSharper = "ReSharper",
    NuGet = "NuGet Package Manager",
    Office = "Microsoft Office",
    NUnit = "NUnit",
    TortoiseGit = "TortoiseGit",
    SourceTree = "SourceTree",
    Kraken = "GitKraken",
    MSTest = "MSTest",
    DevOps = "Azure DevOps",
    TFS = "Team Foundation Server (TFS)",
    TeamCity = "TeamCity",
    Octopus = "Octopus Deploy",
    Sharepoint = "Sharepoint",
    FogBugz = "FogBugz",
    Fiddler = "Fiddler",
    Github = "GitHub",
    Webpack = "Webpack",
    Gulp = "Gulp",
    SQLCompare = "Redgate SQL Compare",
    Photoshop = "Adobe Photoshop",
    PremierePro = "Adobe Premiere Pro"
}
